
<template>
    <div>
        <div id="" class="" style="background-color: white;text-align: center;">
            <div><h1>Modelový  zápis</h1> </div>
            <p>z periodické inventarizace sbírek provedené ve smyslu &#167; 12 zákona 122/2000 Sb.</p>
            <table style="width:800px;margin:50px auto 850px;text-align: left;">
                <tr>
                    <td style="width:50%;">
                        Periodická inventarizace č:
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Předmět periodické inventarizace:</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Počet předmětů ve sbírce / skupině k datu inventarizace:</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Inventarizováno:</td>
                    <td>{{ $store.state.items.length }} inventárních čísel</td>
                </tr>
                <tr>
                    <td>Procentuální vyjádření:</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Způsob provedení inventarizace:</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Místo inventarizace:</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Datum inventarizace:</td>
                    <td>{{ now() }}  </td>
                </tr>
                <tr>
                    <td>Inventarizační komise:</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Stav inventarizovaných předmětů:</td>
                    <td>viz příloha 1</td>
                </tr>
                <tr>
                    <td>Inventární čísla inventarizovaných předmětů:</td>
                    <td>viz příloha 1</td>
                </tr>
                <tr>
                    <td>Umístění sbírkových předmětů:</td>
                    <td>viz příloha 1</td>
                </tr>
                <tr>
                    <td>Soulad se zápisem ve sbírkové evidenci:</td>
                    <td></td>
                </tr>
            </table>
            <br>
            <br>
            <br>
            <table style="width:1000px;margin:50px auto 150px;">
                <tr>
                    <th colspan="7">
                        <h2 style="text-align:center;">
                            Protokol o inventarizaci sbírky
                        </h2>
                    </th>
                </tr>
                <tr>
                    <td>#</td>
                    <td>Inv. číslo</td>
                    <td>Datum:</td>
                    <td>(n)Autoři/sběratelé</td>
                    <td rowspan="2">
                        (n)Název/jméno
                    </td>
                    <td>(n)Datace vzniku/sběru</td>
                    <td>Stálé uložení</td>
                </tr><tr>
                    <td></td>
                    <td>Pří.číslo</td>
                    <td>Stav</td>
                    <td>Popis stavu</td>

                    <td>Počet kusů</td>
                    <td>Půjčeno kam</td>
                </tr>

                <tr v-for="(item,index) in $store.state.items" :key="item.ic">
                    <td>{{ index=1+index }}<br><i class="pi" :class="{'pi-check-square' : item.verified == 'true','pi-stop' : item.verified != 'true' }"></i></td>
                    <td>{{ item.ic }}<br>{{ index=1200+index }}/2022</td>
                    <td>{{ now() }}<br>{{ item.state }}</td>
                    <td>BeePartner<br>-</td>
                    <td> {{ item.name }}</td>
                    <td> {{ item.amount }}</td>
                    <td></td>
                </tr>
            </table>
            <br>
            <br>
        </div>
    </div>
</template>

<script>
export default {
        data() {
                return {
                }
        },
        computed: {
                show() {
                        return true;
                },
        },
        created() {
                this.$store.dispatch('fetchItems')
                this.$store.state.printProtocol = true
        },
        methods: {
                now(){
                        const today = new Date();
                        const date = today.getDate()+'.'+(today.getMonth()+1)+'.'+today.getFullYear()
                        return date
                },

        },
};
</script>

<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: left;
}
.layout-main {
    border-left: 0px solid #B4B4B4;
    border-right: 0px solid #B4B4B4;
}
</style>